<template>
    <report-component :endpoint="this.$apiUrl.powerBi.base" report-id="analytics" :default-error-message="defaultErrorMessage" />
</template>
<script>

import ReportComponent from '~/components/Report.vue';

export default {
    name: 'ReportEdit',

    components: { ReportComponent },

    data() {
        return {
            defaultErrorMessage: 'Analytics report is not assigned',
        }
    },
}
</script>
<style lang="scss">
@import '~/styles/components/_power-bi.scss';
</style>
